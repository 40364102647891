import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { AppsFooter } from '~/components/templates/site-footer/apps-footer'
import { LegalFooter } from '~/components/templates/site-footer/legal-footer'
import { SocialFooter } from '~/components/templates/site-footer/social-footer'
import { cn } from '~/lib/utils'

import { BaselineAndRating } from './baseline-and-rating'

const footerLinks = [
	{
		title: 'cwFooter:cocoons.title',
		links: [
			{
				label: 'cwFooter:offers.meetingRoomsParis',
				href: '/salle-de-reunion/paris',
			},
			{
				label: 'cwFooter:offers.officesParis',
				href: '/bureau-a-l-heure/paris',
			},
			{
				label: 'cwFooter:offers.meetingRoomsLyon',
				href: '/salle-de-reunion/lyon',
			},
			{
				label: 'cwFooter:offers.officesLyon',
				href: '/bureau-a-l-heure/lyon',
			},
		],
	},
	{
		title: 'cwFooter:help.title',
		links: [
			{ label: 'cwFooter:help.who', href: '/a-propos' },
			{ label: 'cwFooter:help.faq', href: 'https://support.cocoon-space.com/' },
			{
				label: 'cwFooter:help.contact',
				href: 'mailto:contact@cocoon-space.com',
			},
		],
	},
] as const

export const SiteFooter = () => {
	const { t } = useTranslation()
	const router = useRouter()

	return (
		<footer
			className={cn('bg-white py-8 print:hidden', {
				'max-md:hidden': router.route === '/dashboard',
			})}
		>
			<div className='container mb-8'>
				<BaselineAndRating />

				<div className='grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-6'>
					{footerLinks.map((section, i) => (
						<ul
							key={i}
							className='flex flex-col gap-y-2 md:col-span-2'
						>
							<li className='font-bold'>{t(section.title)}</li>

							{section.links.map((link) => (
								<li key={link.label}>
									<Link
										href={link.href}
										target='_blank'
										className='text-sm hover:text-primary'
									>
										{t(link.label)}
									</Link>
								</li>
							))}
						</ul>
					))}

					<div className='flex flex-col gap-y-2 md:col-end-7'>
						<p className='font-bold'>{t('cwFooter:app.title')}</p>

						<AppsFooter />
					</div>
				</div>
			</div>

			<div className='container flex flex-col divide-y divide-slate-200'>
				<div className='flex flex-col items-center justify-between gap-4 py-2 md:flex-row'>
					<SocialFooter />

					<LegalFooter />
				</div>

				<div className='py-2 text-right text-slate-500 text-xs'>
					{`Copyright © ${new Date().getFullYear()} Cocoon Space`}
				</div>
			</div>
		</footer>
	)
}
