import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useCookieBannerStore } from '~/components/cookie-banner/cookie-banner.store'

export const LegalFooter = () => {
	const { t } = useTranslation()
	const setIsCookiesOpen = useCookieBannerStore.use.setIsOpen()

	return (
		<div className='flex flex-row gap-4 text-xs md:text-sm'>
			<a
				className='cursor-pointer hover:text-primary'
				onClick={() => {
					if (window === undefined) return
					setIsCookiesOpen(true)
				}}
			>
				{t('cwFooter:cookies')}
			</a>

			<Link
				href='/reglement-cocoon'
				target='_blank'
				className='hover:text-primary'
			>
				{t('cwFooter:rules')}
			</Link>

			<Link
				href='/politique-confidentialite'
				target='_blank'
				className='hover:text-primary'
			>
				{t('cwFooter:confid')}
			</Link>

			<Link
				href='/mentions-legales'
				target='_blank'
				className='hover:text-primary'
			>
				{t('cwFooter:legal')}
			</Link>
		</div>
	)
}
